import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const Profile = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    isAuthenticated && (
      <div className="grip gap-4 grid-cols-2 text-xl text-center">
        <p>Welcome {user.name}!</p>
        <p>You are logged in with {user.email}</p>
      </div>
    )
  );
};

export default Profile;
