import React, { useState, useEffect } from "react";
import { withAuth0 } from "@auth0/auth0-react";
import { Button } from "@material-tailwind/react";


const TestButton = (props) => {

  const [token, setToken] = useState();

  useEffect(() => {
    const fetchToken = async () => {
      const token = await props.auth0.getIdTokenClaims();
      setToken(token);
    }

    fetchToken();
  }, [props.auth0]);

  if (!token) {
    return (
      <p className="text-xl text-center">Log in to view details</p>
    );
  } else {
    return (
      <>
        <Button color="green" onClick={() => console.log(props.auth0)}>
          Click Here
        </Button>
        <div className="text-xl text-center">
          <p> Token Expires At: </p>
          <p>{new Date(token.exp * 1000).toString()}</p>
        </div>
      </>
    );
  };


};

export default withAuth0(TestButton)
