import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@material-tailwind/react";
import AuthToken from "../api/AuthService";


export default function RequestTest() {

  const { isAuthenticated } = useAuth0();
  const [apiResponse, setApiResponse] = useState(null);
  
  const token = AuthToken();


  async function handleClick() {
    fetch("https://wdfx1cj4jj.execute-api.us-east-1.amazonaws.com/testing", {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then(response => response.json())
      .then(json => setApiResponse(json))
      .catch(error => console.log(error));
  }


  return (
    isAuthenticated && (
      <>
        <Button color="green" onClick={handleClick}>
          Who's your favorite Pokemon?
        </Button>
        <div>
          {apiResponse ? (
            <pre>{JSON.stringify(apiResponse.name, null, 2)}</pre>
          ) : (
            <pre></pre>
          )}
        </div>
      </>
    )
  );
};

