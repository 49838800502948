import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@material-tailwind/react";



const LoginButton = () => {
  const { loginWithRedirect, isAuthenticated, isLoading, logout } = useAuth0();

  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => { setLoggedIn(isAuthenticated); }, [isAuthenticated])
  if (!loggedIn) {
    return <Button color="blue" onClick={() => loginWithRedirect()}>Log In</Button>;
  }

  return (
    <Button color="red" onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
      Log Out
    </Button>
  );
};

export default LoginButton;
