import LoginButton from './components/LoginButton';
import Profile from './components/Profile';
import RequestTest from './components/RequestTest';
import TestButton from './components/TestButton';

function App() {
  return (
    <div className="grid gap-4 justify-center">
      <h1 className="text-3xl font-bold text-center grid grid-cols-1 gap-4">
        Welcome
      </h1>
      <div className="grid grid-cols-1 gap-4">
        <LoginButton />
        <Profile />
        <TestButton />
        <RequestTest />
      </div>
    </div>
  );
}

export default App;
