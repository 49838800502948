import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";


export default function AuthToken() {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState();

  useEffect(() => {
    const authParams = {
      audience: "https://api.tonysherman.com",
    };

    const fetchToken = async () => {
      if (isAuthenticated) {
        const tokenResp = await getAccessTokenSilently({ authorizationParams: authParams });
        setToken(tokenResp);
      }
    }
    fetchToken();
  }, [isAuthenticated, setToken, getAccessTokenSilently, token])

  return token;

}
